// WSLR Styles
body {
    @media (orientation: landscape) {
        font-size: 1.5vw;
    }
    @media (orientation: portrait) {
        font-size: 1.5vh;
    }
    background-color: #FFFFFF;
}
.wslr-product {
    &.style-basic-white {
        div, canvas, svg {
            font-family: sans-serif, Helvetica, Arial;
            position: absolute;
            transform:translate(-50%, 0); 
            left: 50%;
        }

        .wslr-product-image {
            width: 100%;
            height: 100%;
            top: calc(10% + 2em);
            left: 0;
            overflow: hidden;
            transform:translate(0, 0); 
            z-index: 9;
            @media (orientation: landscape) {
                top: 0;
                width: 60%;
            }
            @media (orientation: portrait) {
                height: calc(75% - 4em);
            }
        }
        .wslr-product-name {
            z-index: 50;
            font-size: 2em;
            top:0;
            height: 10%;
            width: calc(100% - 2em);
            background: transparent;
            padding: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-hyphens: auto;
            hyphens: auto;
            word-break: normal;
            @media (orientation: landscape) {
                width: calc(40% - 2em);
                height: auto;
                right: 0;
                left: unset;
                transform: translate(0, 0); 
                height: 20%;
                -webkit-line-clamp: 4;
            }
        }
        .wslr-product-description {
            z-index: 40;
            font-size: 1.25em;
            top: calc(80% + 2em);
            width: 62%;
            text-align: left;
            left: 1em;
            transform:translate(0, 0); 
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            @media (orientation: landscape) {
                width: calc(40% - 2em);
                left: unset;
                right: 1em;
                top: 35%;
                -webkit-line-clamp: 6;
            }
        }
        .wslr-product-price {
            z-index: 30;
            font-size: 2em;
            font-weight: bold;
            bottom: 0.5em;
            width: 60%;
            text-align: right;
            left: 5%;
            transform:translate(0, 0); 
            @media (orientation: landscape) {
                width: calc(40% - 2em);
                left: calc(60% + 0.5em);
                text-align: left;
            }
        }
        .wslr-product-link-qrcode {
            z-index: 20;
            border: 1em white solid;
            bottom: 0;
            width: auto;
            left: unset;
            right: 0;
            transform:translate(0, 0); 
            height: 15%;
            @media (orientation: landscape) {
                height: 25%;
            }
        }
        .wslr-product-footer-background {
            z-index: 15;
            bottom: 0;
            width: 100%;
            background: rgba(255,255,255,.8);
            padding: 1em;
            height: 15%;
            @media (orientation: landscape) {
                display: none;
            }
        }
    }
    &.style-fullscreen-overlays-white {
        div, canvas, svg {
            font-family: sans-serif, Helvetica, Arial;
            position: absolute;
            transform:translate(-50%, 0); 
            left: 50%;
        }

        .wslr-product-image {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform:translate(0, 0); 
            z-index: 9;
        }
        .wslr-product-name {
            z-index: 50;
            font-size: 2em;
            top:10%;
            max-width: 50%;
            background: rgba(255,255,255,.8);
            padding: 1em;   
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            -webkit-hyphens: auto;
            hyphens: auto;
            word-break: normal;
        }
        .wslr-product-description {
            z-index: 40;
            font-size: 1.25em;
            
            @media (orientation: landscape) {
                top: 73%;
                width: 65%;
                text-align: left;
                left: 5%;
                transform:translate(0, 0); 
            }
            @media (orientation: portrait) {
                top: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
        .wslr-product-price {
            z-index: 30;
            font-size: 2em;
            font-weight: bold;
            
            @media (orientation: landscape) {
                bottom: 7%;
                width: 65%;
                text-align: right;
                left: 5%;
                transform:translate(0, 0); 
            }
            @media (orientation: portrait) {
                bottom: 25%;
            }
        }
        .wslr-product-link-qrcode {
            z-index: 20;
            border: 20px white solid;
            bottom: 5%;
            width: auto;
                        
            @media (orientation: landscape) {
                left: unset;
                right: 5%;
                transform:translate(0, 0); 
                height: 20%;
            }
            @media (orientation: portrait) {
                height: 15%;
            }
        }
        .wslr-product-footer-background {
            z-index: 15;
            bottom: 0;
            width: 100%;
            background: rgba(255,255,255,.8);
            padding: 1em;
            @media (orientation: landscape) {
                height: 30%;
            }
            @media (orientation: portrait) {
                height: 40%;
            }
        }
    }
}

// Slider Styles

.awssld {
    --content-background-color: #FFFFFF;
    --slider-transition-duration: 775ms;
}

// WSLR Standard Slides

// Infoslide

.infoslide {
    width: 100vW;
    height: 100vH;
    background-color: #FFFFFF;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (orientation: landscape) {
        background-image: url('/wslr/slides/WSLR-Infoslide_landscape.jpg');
    }
    @media (orientation: portrait) {
        background-image: url('/wslr/slides/WSLR-Infoslide_portrait.jpg');
    }
}